import React from 'react';
import PropTypes from 'prop-types';
import styled from 'react-emotion';
import InterfaceContext from '../../context/InterfaceContext';

import { breakpoints, colors, fonts, spacing } from '../../utils/styles';

const ProductSpecsRoot = styled(`div`)`
  padding: 0 ${spacing.md}px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: ${spacing['2xl']}px ${spacing.xl}px 0;
  }
`;

const Name = styled(`h1`)`
  color: ${colors.brandDark};
  font-family: ${fonts.heading};
  font-size: 1.8rem;
  font-weight: 500;
  margin: 0;
`;

const Description = styled(`p`)`
  color: ${colors.text};
  font-size: 1rem;
  line-height: 1.5;
`;

const Price = styled(`div`)`
  color: ${colors.brand};
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: -0.02em;

  span {
    color: ${colors.textLight};
  }
`;

const removeCareInstructions = desc =>
  desc.split(/Care Instructions/).slice(0, 1);

const ProductSpecs = props => {
  const {
    product: {
      title,
      description,
      variants
    },
  } = props;

  const { price } = variants[0];

  const getVariantPrice = (shopifyId, variants) => {
    let currentPrice = price

    variants.map((variant) => {
      if (variant.shopifyId === shopifyId) {
        currentPrice = variant.price
      }
    })

    return currentPrice
  }

  return (
    <InterfaceContext.Consumer>
      {({
        currentProductVariant
      }) => (
    <ProductSpecsRoot>
      <Name>{title}</Name>
      <Description>{removeCareInstructions(description)}</Description>
      <Price>
        ${currentProductVariant ? getVariantPrice(currentProductVariant, variants): price}
      </Price>
    </ProductSpecsRoot>
      )}
    </InterfaceContext.Consumer>
  );
};

ProductSpecs.propTypes = {
  product: PropTypes.object.isRequired
};

export default ProductSpecs;
